import {useEffect, useState} from "react";
import {OrgSettings} from "../../models/org/orgSettings";
import {LangKey, useGlobalContext, useUserContext} from "../globalContext";
import {addNotificationWithButton} from "../../utils/toast/toast";

export function useOrgSettings() {
    const {api, i18n} = useGlobalContext()
    const {user, currentMenuId, setOrgInfo} = useUserContext()
    const [selectedLogoImage, setSelectedLogoImage] = useState<File | null>(null);
    const [modal, setModal] = useState<boolean>(false);
    const [mapModal, setMapModal] = useState(false);
    const [lastSubdomain, setLastSubdomain] = useState<string>("");

    const [org, setOrg] = useState<OrgSettings>({
        name: "",
        address: "",
        logoImg: null,
        subdomain: "",
        defaultLang: "RU",
        customDomain: null,
        lat: null,
        lon: null
    })
    useEffect(() => {
        if (user?.org) {
            setOrg({
                name: user?.org.name,
                address: user?.org.address,
                logoImg: user?.org.logoImg,
                subdomain: user?.org.subdomain,
                defaultLang: user?.org.defaultLang,
                customDomain: user?.org.customDomain,
                lon: user?.org.lon || null,
                lat: user?.org.lat || null
            })
            setLastSubdomain(user?.org.subdomain)
        }
    }, [user?.org])

    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    function handleChangeName(s: string) {
        setOrg({...org, name: s})
    }

    function handleChangeAddress(s: string) {
        setOrg({...org, address: s})
    }

    function handleDeleteLogoImg() {
        setOrg({...org, logoImg: null})
    }

    function handleChangeSubdomain(s: string) {
        setOrg({...org, subdomain: s})
    }

    function handleChangeCustomDomain(s: string | null) {
        if (s?.trim() === "") s = null
        setOrg({...org, customDomain: s})
    }

    function handleChangeDefaultLang(s: LangKey) {
        setOrg({...org, defaultLang: s})
    }

    function subdomainIsChanged() {
        return org.subdomain !== lastSubdomain
    }

    function handleUploadLogoImg(file: File | null) {
        setSelectedLogoImage(file)
        setModal(true)
    }

    function changeCoordinatesAndAddress(coordinates: number[], address: string) {
        setOrg({
            ...org,
            address: address,
            lat: coordinates[0],
            lon: coordinates[1]
        })
    }

    function UploadLogoImage(file: File) {
        api.saveLogoImage({setLoading: setUploadLoading}, file).then((res) => {
            if (res.data) {
                setOrg({...org, logoImg: res.data.newFilename})
            }
        })
    }

    function handleSave() {
        const save = () => {
            if (user?.org) {
                api.saveOrgSettings({setLoading: setSaveLoading}, org, user?.org.id).then((res) => {
                    if (res.data) {
                        setOrgInfo(org.name, org.subdomain, org.address, org.defaultLang, org.logoImg, org.customDomain)
                    }
                })
            }
        }
        if (subdomainIsChanged()) {
            addNotificationWithButton({
                message: i18n.q_send_subdomain(),
                onSubmit: save,
                loading: saveLoading,
                i18n
            })
        } else {
            save()
        }
    }

    return {
        org,
        user,
        modal,
        currentMenuId,
        selectedLogoImage,
        setSelectedLogoImage,
        setModal,
        handleChangeName,
        handleChangeAddress,
        handleUploadLogoImg,
        handleDeleteLogoImg,
        handleChangeSubdomain,
        handleChangeDefaultLang,
        UploadLogoImage,
        handleSave,
        handleChangeCustomDomain,
        saveLoading,
        uploadLoading,
        mapModal,
        setMapModal,
        changeCoordinatesAndAddress,
    }
}