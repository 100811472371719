import {NavLink} from "react-router-dom";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";

export function OrgSettingsTab() {
    const {i18n} = useGlobalContext()
    const {iikoActiveGeneral, user} = useUserContext()
    return (
        <ul className="nav default-tabs mb-2 mb-lg-3">

            <li className="default-tabs__item">
                <NavLink className="default-tabs__link" end={true} to="/organization-settings">
                    {i18n.organization_settings()}
                </NavLink>
            </li>
            {user?.org?.availablePaymentMethods && user?.org?.availablePaymentMethods.includes("onevision") &&
                <li className="default-tabs__item">
                    <NavLink className="default-tabs__link" end={true} to="/organization-one-vision">
                        {i18n.one_vision_payment()}
                    </NavLink>
                </li>
            }
            {user?.org?.availablePaymentMethods && user?.org?.availablePaymentMethods.includes("liqpay") &&
                <li className="default-tabs__item">
                    <NavLink className="default-tabs__link" end={true} to="/organization-liqpay">
                        {i18n.liqpay_payment()}
                    </NavLink>
                </li>
            }
            {user?.org?.availablePaymentMethods && user?.org?.availablePaymentMethods.includes("kaspi") &&
                <li className="default-tabs__item">
                    <NavLink className="default-tabs__link" end={true} to="/organization-kaspi">
                        {i18n.kaspi_payment()}
                    </NavLink>
                </li>
            }
            {user?.org?.availablePaymentMethods && user?.org?.availablePaymentMethods.includes("jetpay") &&
                <li className="default-tabs__item">
                    <NavLink className="default-tabs__link" end={true} to="/organization-jetpay">
                        {i18n.jetpay_payment()}
                    </NavLink>
                </li>
            }
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link" end={true} to="/organization-domain">
                    {i18n.domain()}
                </NavLink>
            </li>
            {user?.isStaff &&
                <li className="default-tabs__item">
                    <NavLink className="default-tabs__link" end={true} to="/organization-additional">
                        Дополнительно
                    </NavLink>
                </li>
            }
            {user?.isStaff &&
                <li className="default-tabs__item">
                    <NavLink className="default-tabs__link" end={true} to="/organization-yandex">
                        Yandex
                    </NavLink>
                </li>
            }
        </ul>
    )
}
