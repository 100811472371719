import {FullscreenControl, GeolocationControl, Map, Placemark, SearchControl, useYMaps,} from '@pbe/react-yandex-maps';
import React, {useEffect, useState} from "react";
import {IGeocodeResult} from "yandex-maps";
import {useGlobalContext} from "../../hooks/globalContext";

interface MapModalProps {
    modal: boolean;
    setModalOpen: (open: boolean) => void;
    handleCoordinatesChange: (coors: number[], address: string) => void;
    lat: number | null,
    lon: number | null
}

const CENTER = [43.240607065190964, 76.91981101091912]
const ZOOM = 13

interface IAddress {
    location: string;
    route: string;
}


export default function MapModal({modal, setModalOpen, handleCoordinatesChange, lat, lon}: MapModalProps) {
    const {i18n} = useGlobalContext()
    const [coordinates, setCoordinates] = useState<number[]>([])
    const ymaps = useYMaps(["geocode"]);
    const [address, setAddress] = useState<IAddress | null>(null);

    useEffect(() => {
        if (lat && lon) {
            coordinates[0] = lat;
            coordinates[1] = lon
        }
    }, [lat, lon]);

    const handleClickMap = (e: any) => {
        const coords = e.get("coords");
        setCoordinates(coords);

        if (ymaps) {
            ymaps
                ?.geocode(coords)
                .then((result) => {
                    const foundAddress = handleGeoResult(result);
                    if (foundAddress) setAddress(foundAddress);
                })
                .catch((error: unknown) => {
                    console.log("Ошибка геокодирования", error);
                    setAddress(null);
                });
        }
    };

    function handleGeoResult(result: IGeocodeResult) {
        const firstGeoObject = result.geoObjects.get(0);

        if (firstGeoObject) {
            const properties = firstGeoObject.properties;

            const location = String(properties.get("description", {}));
            const route = String(properties.get("name", {}));

            const foundAddress = {
                location,
                route
            };

            return foundAddress;
        }
    }

    return (
        <div className={modal ? "form modal effect-scale show" : "modal effect-scale"}
             style={{display: modal ? "block" : "none"}}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content p-4">
                    <Map defaultState={{
                        center: coordinates && coordinates.length > 0 ? coordinates : CENTER,
                        zoom: ZOOM
                    }}
                         style={{width: '100%', height: '350px'}}
                         onClick={handleClickMap}
                    >
                        <GeolocationControl options={{float: "left"}}/>
                        <FullscreenControl/>
                        <SearchControl options={{float: "right"}}/>
                        {
                            coordinates && <Placemark geometry={coordinates}/>
                        }
                    </Map>
                    <div className="form__buttons mt-4">
                        <div className="form__buttons-left">
                            <div className="button -blue modal__button"
                                 onClick={(e) => {
                                     handleCoordinatesChange(coordinates, address?.location + ", " + address?.route);
                                     setModalOpen(false);
                                 }
                                 }
                            >
                                {i18n.save()}
                            </div>
                            <button aria-label="Close"
                                    className="button -bordered_blue modal__button"
                                    onClick={() => setModalOpen(false)}
                                    data-dismiss="modal" type="button">{i18n.cancel()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}