import {SubmitButton} from "../input/submitButton";
import React, {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {InputText} from "../input/inputText";
import {FormWithLoader} from "../loader/formWithLoader";
import {InputPhone} from "../input/phoneInput";
import {Phone} from "../../models/other/phone";
import {YandexSettings} from "../../models/requests/yandexSettings";

export function YandexSettingsForm() {
    const {i18n, api} = useGlobalContext()
    const {user} = useUserContext()
    const [apiKey, setApiKey] = useState<string>("")
    const [restaurantContactName, setRestaurantContactName] = useState<string>("")
    const [restaurantContact, setRestaurantContact] = useState<Phone>({} as Phone)

    const [loading, setLoading] = useState<boolean>(false)
    const [createDeleteLoading, setCreateDeleteLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (user?.org) {
            api.getYandexSettings({controller, setLoading}, user?.org.id).then((res) => {
                if (res.data) {
                    setApiKey(res.data.token || "")
                    setRestaurantContactName(res.data.restaurantContactName || "")
                    setRestaurantContact({
                        number: res.data.restaurantContactPhone || "",
                        countryCode: "kz"
                    } || {number: "", countryCode: "kz"})
                }
            })
        }
        return () => controller.abort()
    }, [user?.org])

    function onSave() {
        const yandexRequest: YandexSettings = {
            organizationId: user?.org?.id,
            token: apiKey,
            restaurantContactName: restaurantContactName,
            restaurantContactPhone: restaurantContact.number,
            number: 0,
        }

        api.createYandexTokenSettings({setLoading: setCreateDeleteLoading}, yandexRequest).then((res) => {
            if (res.data) {
            }
        })
    }

    function onDelete() {
        // if (kaspiSettings?.id) {
        //     api.deleteKaspiPaymentSettings({setLoading:setCreateDeleteLoading},kaspiSettings.id).then((res) => {
        //         if (res.data) {
        //             setKaspiSettings(null)
        //         }
        //     })
        // }
    }

    return (
        <FormWithLoader isloading={loading} className="form">
            <InputText label={i18n.api_key()} value={apiKey} onChange={setApiKey} isValidate={true}/>
            <InputText label={"Представитель ресторана"} value={restaurantContactName}
                       onChange={setRestaurantContactName} isValidate={true}/>
            <InputPhone label={"Номер телефона ресторана"} value={restaurantContact}
                        onChange={setRestaurantContact}/>
            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={onSave} label={i18n.save()}
                                  disabled={false} isLoading={createDeleteLoading}
                                  modal={true}/>
                </div>
            </div>
        </FormWithLoader>
    )
}