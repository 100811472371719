import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext} from "../../hooks/globalContext";
import React, {useEffect} from "react";
import {OrgSettingsTab} from "../../components/tabs/orgSettingsTab";
import {AdditionalSettingsModule} from "../../components/form/additionalSettingsModule";

export function AdditionalSettings() {
    const {i18n, userInfoLoading} = useGlobalContext()
    let infos = [
        {link: "/organization-settings", title: i18n.organization_settings(), index: "breadcrumbs-id"}
    ]
    useEffect(() => {
        document.title = i18n.menu_creating()
    }, [i18n])


    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={infos}/>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.organization_settings()}</h1>
                    </div>
                </div>

                {<OrgSettingsTab/>}

                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="tab-content">
                            <div className="default-card">

                                {/*<OrgSettingsForm/>*/}
                                <AdditionalSettingsModule/>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}
